import "./Prices.scss"

import { getData } from "../../../service/service"
import { useState, useEffect } from "react"
import Button from "../../Common/Button/Button"
import { spinner } from "../../../service/image"

const Prices = () => {

	const [prices, setPrices] = useState(null)

	useEffect(() => {
		getData("prices.json").then(data => {
			setPrices(data)
		})
	}, [])

	return (
		<section className="prices">
			<div className="container">
				<h2>Ціни</h2>
				<div className="prices__wrapper">
					{
						prices ?
							<table>
								<tbody>
									{
										prices.map(el => {
											return (
												<tr key={el.id} className="prices__wrapper-item">
													<td>{el.serviceName}</td>
													<td>{el.price}</td>
													<td> <Button btnClass={"prices__btn btn-request"} btnText={"Консультація"} /> </td>
												</tr>
											)
										})
									}
								</tbody>
							</table> : <img style={{ display: "block", margin: "0px auto", width: "80px", height: "80px" }} src={spinner} alt="Загрузка..." />
					}
				</div>
				<Button btnClass={"prices__mobile btn-request"} btnText={"Консультація"} />
			</div>
		</section>
	)
}

export default Prices
import "./ContactForm.scss"
import Form from "../../Common/Form/Form"

const ContactForm = () => {

	return (
		<section className="contact-from">
			<div className="container">
				<Form />
			</div>
		</section>
	)
}

export default ContactForm
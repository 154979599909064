import Prices from "./Prices/Prices"

const PricePage = () => {
	return (
		<main>
			<Prices />
		</main>
	)
}

export default PricePage
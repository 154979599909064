import "./ServiceHero.scss"

const ServiceHero = ({ currService }) => {
	const { icon, image, serviceName } = currService

	return (
		<section className="service-hero" style={{ background: `url(${image}) center center/cover no-repeat` }}>
			<div className="container">
				<div className="service-hero__wrapper">
					<img src={icon} alt="icon" />
					<h2>{serviceName}</h2>
				</div>
			</div>
		</section>
	)
}

export default ServiceHero
const _BASE_URL = "/data/"
const _API_URL = process.env.REACT_APP_API_URL
const _TG_TOKEN_BOT = process.env.REACT_APP_TG_TOKEN_BOT;
const _CHAT_ID = process.env.REACT_APP_CHAT_ID;
const _TG_URL = `https://api.telegram.org/bot${_TG_TOKEN_BOT}/sendMessage`
const API_KEY = process.env.REACT_APP_API_KEY;

async function getData(param) {
	let res = await fetch(`${_BASE_URL + param}`)
	return res.json()
}

async function getDataDFromDB(params) {
	try {
		let res = await fetch(_API_URL + params, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"api-key": API_KEY
			}
		})
		let data = await res.json()
		return data
	} catch (error) {
		return error
	}
}

async function sendData(message) {
	try {
		return await fetch(_TG_URL, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				chat_id: _CHAT_ID,
				text: message,
				parse_mode: "html"
			}),
		})
	} catch (error) {
		return error
	}
}

export { getData, sendData, getDataDFromDB }


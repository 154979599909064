import 'swiper/css';
import 'swiper/css/pagination';
import "./Reviews.scss"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { getData } from '../../../service/service';
import { useState, useEffect } from 'react';

const Reviews = () => {

	const [reviews, setReviews] = useState(null)

	useEffect(() => {
		getData("reviews.json").then(data => {
			setReviews(data)
		})
	}, [])

	return (
		<section className="reviews" id='reviews'>
			<div className="container">
				<h2>Відгуки</h2>
				<Swiper
					slidesPerView={1}
					spaceBetween={20}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						768: {
							slidesPerView: 2,
							spaceBetween: 40,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 20,
						},
					}}
					modules={[Pagination]}
					className="mySwiper"
				>
					{
						reviews && reviews.map(rev => {
							return (
								<SwiperSlide key={rev.id}>
									<div className="reviews__item">
										<div className="reviews__item-photo">
											<img src={rev.photo} alt="user" />
										</div>
										<h3>{rev.user}</h3>
										<p>{rev.comment}</p>
									</div>
								</SwiperSlide>
							)
						})
					}
				</Swiper>
			</div>
		</section>
	)
}

export default Reviews
import "./ServiceCard.scss"
import { Link } from "react-router-dom"

const ServiceCard = ({ id, icon, image, name }) => {

	return (
		<div className="service-card" style={{ background: `url(${image}) center center/cover no-repeat` }}>
			<div className="service-card__wrapper">
				<img src={icon} alt="icon" />
				<h3>{name}</h3>
				<Link to={`/services/${id}`} className="nav-link">Детальніше</Link>
			</div>
		</div>
	)
}

export default ServiceCard
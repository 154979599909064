import "./Accordion.scss"
import { polygon } from "../../../service/image"
import { useState } from "react"

const Accordion = ({ title, htmlCode }) => {

	const [openAcc, setOpenAcc] = useState(false)

	return (
		<div className="accordion__item">
			<div className="accordion__item-preview" onClick={() => setOpenAcc(prev => !prev)}>
				<h3>
					{title}
				</h3>
				<img src={polygon} alt="polygon" className={`accordion__item-polygon ${openAcc ? "active" : ""}`} />
			</div>
			<div className={`accordion__item-content ${openAcc ? "active" : ""}`}>
				<div className="accordion__item-text" dangerouslySetInnerHTML={{ __html: htmlCode }}>
				</div>
			</div>
		</div>
	)
}

export default Accordion
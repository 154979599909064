import "./Form.scss"

import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { sendData } from "../../../service/service";

const Form = () => {
	const schema = yup.object().shape({
		userName: yup.string().required("Це поле є обов'язковим!"),
		userPhone: yup
			.string()
			.matches(/^[0-9+() -]*$/, "Введіть вірний номер телефону!")
			.min(10, "Телефон має містити мінімум 10 символів!")
			.required("Це поле є обов'язковим!"),
		userComment: yup.string()
	});

	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const [formStatus, setFormStatus] = useState(null);

	const sendForm = (data) => {
		let message = `Заявка з сайту\n\nІм'я: ${data.userName}\nТелефон: ${data.userPhone}\n${data.userComment ? "Коментар:\n" + data.userComment : "Коментар відсутній"}`;
		sendData(message).then(data => {
			if (data.ok) {
				setFormStatus("Вашу заявку успішно надіслано")
				setTimeout(() => {
					reset()
					setFormStatus("")
				}, 3000)
			} else {
				setFormStatus("Щось пішло не так, спробуйте пізніше...")
			}
		})
	}

	return (
		<form className="form-data" onSubmit={handleSubmit(sendForm)}>
			<h2>Заповніть форму і я з Вами зв'яжусь</h2>
			<input
				type="text"
				placeholder="Ваше Прізвище та ім’я"
				{...register('userName')}
			/>
			{errors.userName && <p className="error">{errors.userName.message}</p>}
			<input
				type="tel"
				placeholder="Номер телефону"
				{...register('userPhone')}
			/>
			{errors.userPhone && <p className="error">{errors.userPhone.message}</p>}
			<input
				type="text"
				placeholder="Коментар"
				{...register('userComment')}
			/>

			{formStatus && <p className="form-status" style={{ textAlign: "center" }}>{formStatus}</p>}
			<button>Відправити</button>
		</form>
	)
}

export default Form
import { useContext } from "react"
import ModalContext from "../../../contexts/ModalContext"

const Button = ({ btnText, btnClass }) => {

	const { setActiveModal } = useContext(ModalContext)

	return (
		<button onClick={() => setActiveModal(true)} className={`btn ${btnClass}`}>{btnText}</button>
	)
}

export default Button
import "./ServicePage.scss"
import ServiceHero from "./ServiceHero/ServiceHero"
import Accordion from "./Accordion/Accordion"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { spinner } from "../../service/image"

const ServicePage = ({ services }) => {
	const [currService, setCurrService] = useState(null)

	const { serviceId } = useParams()

	useEffect(() => {
		if (services) {
			let findedService = services.allServices.find(el => el.id === serviceId)
			setCurrService(findedService)
		}
	}, [services, serviceId])

	return (
		<main>
			{
				currService ?
					<>
						<ServiceHero currService={currService} />

						<section className="accordion">
							<div className="container">
								<div className="accordion__wrapper row">
									{
										currService.categories.map(category => {
											return (
												<Accordion key={category.id} title={category.title} htmlCode={category.content} />
											)
										})
									}
								</div>
							</div>
						</section>
					</> : <img style={{ display: "block", margin: "60px auto", width: "80px", height: "80px" }} src={spinner} alt="Загрузка..." />
			}
		</main>
	)
}

export default ServicePage
import "./ServicesList.scss"
import ServiceCard from "../../Common/ServiceCard/ServiceCard"
import { spinner } from "../../../service/image"

const ServicesList = ({ services }) => {
	return (
		<section className="services">
			<div className="container">
				<h2>Послуги Адвоката</h2>
				<div className="services__wrapper row">
					{
						services ? services.allServices.map(service => {
							return (
								<ServiceCard key={service.id} id={service.id} icon={service.icon} image={service.image} name={service.serviceName} />
							)
						}) : <img style={{ display: "block", margin: "0 auto", width: "80px", height: "80px" }} src={spinner} alt="Загрузка..." />
					}
				</div>
			</div>
		</section>
	)
}

export default ServicesList
import { instagramIcon, telegramIcon, facebookIcon, addressIcon, emailIcon, clockIcon } from "../../../../service/image.js"


const HeaderTop = ({ mainInfo }) => {

	return (
		<div className="header__top">
			<div className="container">
				<div className="header__top-wrapper row">
					<div className="header__top-schedule">
						<img src={clockIcon} alt="clockIcon" />
						<span>
							{mainInfo.schedule}
						</span>
					</div>
					<figure></figure>
					<div className="header__top-social row">
						<a href={mainInfo.social.facebook} className="row">
							<img src={facebookIcon} alt="social-icon" />
							<span>Facebook</span>
						</a>
						<a href={mainInfo.social.instagram} className="row">
							<img src={instagramIcon} alt="social-icon" />
							<span>Instagram</span>
						</a>
						<a href={mainInfo.social.telegram} className="row">
							<img src={telegramIcon} alt="social-icon" />
							<span>Telegram</span>
						</a>
					</div>
					<figure></figure>
					<div className="header__top-email">
						<a href={`mailto:${mainInfo.email}`}><img src={emailIcon} alt="icon" /><span>{mainInfo.email}</span></a>
					</div>
					<figure></figure>
					<div className="header__top-address">
						<a href={mainInfo.addressLink}><img src={addressIcon} alt="icon" /><span>{mainInfo.address}</span></a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HeaderTop
import "./About.scss"

const About = ({ mainInfo }) => {

	return (
		<section className="about" id="about">
			<div className="container">
				<div className="about__wrapper row">
					<div className="about__photo">
						<h2 className="about__title-mobile">Про мене</h2>
						<img src={mainInfo && mainInfo.aboutOwner} alt="owner" />
						<div className="about__name">
							<div className="about__name-owner">
								Євген Гаркуша
							</div>
							<div className="about__name-proffesion">
								Адвокат
							</div>
						</div>
					</div>
					<div className="about__content">
						<h2 className="about__title-pc">Про мене</h2>
						<div className="about__content-info">
							<h3>Мій юридичний шлях</h3>
							<ul>
								<li>02.02.2004: Помічник юристконсульта на КП "Пирятинський сирзавод".</li>
								<li>01.07.2005: Помічник адвоката в Адвокатському об'єднанні "Національна колегія адвокатів".</li>
								<li>28.02.2008: Отримав статус адвоката в АТ "Національна колегія адвокатів".</li>
								<li>22.08.2013: Керівник Адвокатського Бюро "Гаркуша та партнери".</li>
							</ul>
							<h3>Досвід</h3>
							<p>Досяг значних результатів у кримінальних справах: припинення кримінального переслідування, перекваліфікація звинувачень, зміна запобіжного заходу, звільнення з-під варти.</p>
							<h3>Практика</h3>
							<ul>
								<li>Цивільне право: сімейне, земельне, житлове, трудове, спадкове, фінансове.</li>
								<li>Інші галузі: податкове, митне, господарське, адміністративне право.</li>
							</ul>
							<h3>Професійна філософія</h3>
							<p>Законодавство постійно змінюється, тому завжди тримаю руку на пульсі. Понад 10 років допомагаю клієнтам вирішувати правові проблеми, маючи індивідуальний підхід до кожного.</p>
							<a target="blank" href="/img/temp/adv.jpeg">&#128279;<span>Документи на право зайняття адвокатською діяльністю</span></a>
							<a target="blank" href="/img/temp/vytyag.jpg">&#128279;<span>Витяг з Єдиного Реєстру адвокатів України</span></a>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default About
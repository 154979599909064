import { useNavigate } from "react-router-dom";

const useNavigateScroll = () => {
	const navigate = useNavigate();

	const handleClickLink = (e, id, path = "/") => {
		e.preventDefault()
		navigate(path);
		setTimeout(() => {
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}, 80);
	};

	return handleClickLink;
};

export default useNavigateScroll;
import "./Footer.scss"
import { instagramIcon, facebookIcon, telegramIcon, addressIcon, emailIcon, phoneCall } from "../../../service/image"

const Footer = ({ mainInfo }) => {

	return (
		<footer>
			<div className="container">
				{
					mainInfo &&
					<div className="footer__wrapper row">
						<div className="footer__address footer__item">
							<a target="blank" href={mainInfo.addressLink} className="special-link-row special-link-address">
								<img src={addressIcon} alt="icon" className="special-link-address-icon" />
								<span>
									{mainInfo.address}
								</span>
							</a>
							<a href={`mailto:${mainInfo.email}`} className="special-link-row">
								<img src={emailIcon} alt="icon" className="special-link-email-icon" />
								<span>
									{mainInfo.email}
								</span>
							</a>
						</div>

						<div className="footer__social footer__item">
							<a href={mainInfo.social.facebook}>
								<img src={facebookIcon} alt="icon" />
							</a>
							<a href={mainInfo.social.instagram}>
								<img src={instagramIcon} alt="icon" />
							</a>
							<a href={mainInfo.social.telegram}>
								<img src={telegramIcon} alt="icon" />
							</a>
						</div>

						<div className="footer__phones footer__item">
							<a href={`tel:${mainInfo.phones.first}`} className="special-link-row">
								<img src={phoneCall} alt="icon" />
								<span>
									{mainInfo.phones.firstPreview}
								</span>
							</a>
							<a href={`tel:${mainInfo.phones.second}`} className="special-link-row">
								<img src={phoneCall} alt="icon" />
								<span>
									{mainInfo.phones.secondPreview}
								</span>
							</a>
						</div>
					</div>
				}
			</div>
		</footer>
	)
}

export default Footer
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect, useState } from "react"
import { getData, getDataDFromDB } from "../../service/service"
import ModalContext from "../../contexts/ModalContext"
import Header from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
import HomePage from "../HomePage/HomePage"
import ServicesPage from "../ServicesPage/ServicesPage"
import ServicePage from "../ServicePage/ServicePage"
import ContactPage from "../ContactPage/ContactPage"
import PricePage from "../PricePage/PricePage"
import SideMenu from "../Common/SideMenu/SideMenu"
import Modal from "../Common/Modal/Modal"
import FixedWindow from "../Common/FixedWindow/FixedWindow"

const App = () => {
	const [mainInfo, setMainInfo] = useState(null)
	const [allServices, setAllServices] = useState(null)
	const [activeSidemenu, setActiveSidemenu] = useState(false)
	const [activeModal, setActiveModal] = useState(null)

	useEffect(() => {
		getDataDFromDB("services").then(data => {
			console.log("Start...", data)
			if (data && data.status) {
				setAllServices(data.services)
			} else {
				getData("services.json").then(data => setAllServices(data))
			}
		})
		getData("contact.json").then(data => setMainInfo(data))
	}, [])

	return (
		<BrowserRouter>
			<ModalContext.Provider value={{ setActiveModal }}>
				<Header mainInfo={mainInfo} sideControl={{ activeSidemenu, setActiveSidemenu }} />
				<SideMenu mainInfo={mainInfo} sideControl={{ activeSidemenu, setActiveSidemenu }} />
				<Routes>
					<Route path="/" element={<HomePage mainInfo={mainInfo} allServices={allServices} />} />
					<Route path="/services" element={<ServicesPage services={allServices} />} />
					<Route path="/services/:serviceId" element={<ServicePage services={allServices} />} />
					<Route path="/contacts" element={<ContactPage mainInfo={mainInfo} />} />
					<Route path="/prices" element={<PricePage />} />
				</Routes>
				<Modal activeModal={activeModal} setActiveModal={setActiveModal} />
				<FixedWindow mainInfo={mainInfo} />
				<Footer mainInfo={mainInfo} />
			</ModalContext.Provider>
		</BrowserRouter>
	)
}

export default App
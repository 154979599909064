import "./Header.scss"

import { Link } from "react-router-dom"
import { logo, phoneCall } from "../../../service/image"
import useNavigateScroll from "../../../hooks/useNavigateScroll"
import HeaderTop from "./HeaderTop/HeaderTop"
import Button from "../Button/Button"

const Header = ({ mainInfo, sideControl }) => {
	const { activeSidemenu, setActiveSidemenu } = sideControl

	const handleClickLink = useNavigateScroll()

	return (
		<header>
			{
				mainInfo ? <HeaderTop mainInfo={mainInfo} /> : ""
			}
			<div className="container">
				<div className="header__bottom row">
					<div className="header__bottom-left row">
						<div className="header__bottom-logo">
							<Link to={"/"} className="nav-link">
								<img className="logo-img" src={logo} alt="logo" />
							</Link>
						</div>
						<nav className="header__bottom-nav row">
							<Link to={"/"} className="nav-link">Головна</Link>
							<a onClick={(e) => handleClickLink(e, "about")} href="#about" className="nav-link">Про мене</a>
							<Link to={"/services"} className="nav-link">Послуги</Link>
							<a onClick={(e) => handleClickLink(e, "reviews")} href="#reviews" className="nav-link">Відгуки</a>
							<Link to={"/prices"} className="nav-link">Ціни</Link>
							<Link to={"/contacts"} className="nav-link">Контакти</Link>
						</nav>
					</div>

					<div className="header__bottom-hot row">
						{
							mainInfo ?
								<div className="header__bottom-phones row">
									<a href={`tel:${mainInfo.phones.first}`}>
										<img src={phoneCall} alt="icon" />
										<span>
											{mainInfo.phones.firstPreview}
										</span>
									</a>
									<a href={`tel:${mainInfo.phones.second}`}>
										<img src={phoneCall} alt="icon" />
										<span>
											{mainInfo.phones.secondPreview}
										</span>
									</a>
								</div> : ""
						}
						<Button btnText={"Консультація"} btnClass={"btn-request"} />
					</div>

					<div className="header__bottom-mobile">
						<div className="mobile__phone">
							{
								mainInfo && <a href={`tel:${mainInfo.phones.first}`}>
									<img src={phoneCall} alt="icon" />
								</a>
							}

						</div>
						<div
							className={`header__bottom-burger ${activeSidemenu ? "active" : ""}`}
							onClick={() => setActiveSidemenu(prev => !prev)}
						>
							<figure></figure>
							<figure></figure>
							<figure></figure>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
import "./FixedWindow.scss"
import { emailIcon, phoneCall } from "../../../service/image"

const FixedWindow = ({ mainInfo }) => {
	return (
		<div className="fixed-window">
			{
				mainInfo && <div className="fixed-window__row">
					<a href={`tel:${mainInfo.phones.first}`}>
						<img src={phoneCall} alt="icon" />
						<span>
							Позвонити
						</span>
					</a>
					<a href={mainInfo.social.telegram}>
						<img src={emailIcon} alt="icon" />
						<span>
							Написати
						</span>
					</a>
				</div>
			}
		</div>
	)
}

export default FixedWindow
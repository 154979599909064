import ServicesList from "./ServicesList/ServicesList"

const ServicesPage = ({ services }) => {
	return (
		<main>
			<ServicesList services={services} />
		</main>
	)
}

export default ServicesPage
import ContactInfo from "./ContactInfo/ContactInfo"
import ContactForm from "./ContactForm/ContactForm"

const ContactPage = ({ mainInfo }) => {
	return (
		<main>
			<ContactInfo mainInfo={mainInfo} />
			<ContactForm />
		</main>
	)
}

export default ContactPage
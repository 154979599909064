import "./ContactUs.scss"

import Form from "../../Common/Form/Form"
import Map from "../../Common/Map/Map"

const ContactUs = ({ mainInfo }) => {

	return (
		<section className="contact-us">
			<div className="container">
				<div className="contact-us__wrapper row">
					<div className="contact-us__form">
						<Form />
					</div>
					<div className="contact-us__map">
						<Map mainInfo={mainInfo} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default ContactUs
import "./ContactInfo.scss"
import Map from "../../Common/Map/Map"
import { instagramIcon, facebookIcon, telegramIcon, addressIcon, phoneCall, emailIcon, spinner } from "../../../service/image"

const ContactInfo = ({ mainInfo }) => {

	return (
		<section className="contact-info">
			<div className="container">
				{
					mainInfo ?
						<div className="contact-info__wrapper row">
							<div className="contact-info__content">
								<div className="contact-info__item contact-info__address" >
									<h3>Адреса:</h3>
									<a href={mainInfo.addressLink}>
										<img src={addressIcon} alt="icon" />
										<span>
											{mainInfo.address}
										</span>
									</a>
								</div>
								<div className="contact-info__item contact-info__phones">
									<h3>Телефони:</h3>
									<a href={mainInfo.phones.first}>
										<img src={phoneCall} alt="icon" />
										<span>
											{mainInfo.phones.firstPreview}
										</span>
									</a>
									<br />
									<a href={mainInfo.phones.second}>
										<img src={phoneCall} alt="icon" />
										<span>
											{mainInfo.phones.secondPreview}
										</span>
									</a>
								</div>
								<div className="contact-info__item">
									<h3>Соц.мережі:</h3>
									<div className="contact-info__social row">
										<a href={mainInfo.social.facebook}>
											<img src={facebookIcon} alt="icon" />
											<span>Facebook</span>
										</a>
										<a href={mainInfo.social.instagram}>
											<img src={instagramIcon} alt="icon" />
											<span>Instagram</span>
										</a>
										<a href={mainInfo.social.telegram}>
											<img src={telegramIcon} alt="icon" />
											<span>Telegram</span>
										</a>
									</div>
								</div>
								<div className="contact-info__item contact-info__email">
									<h3>Email:</h3>
									<a href={`mailto:${mainInfo.email}`}>
										<img src={emailIcon} alt="icon" />
										<span>
											{mainInfo.email}
										</span>
									</a>
								</div>
							</div>
							<div className="contact-info__map">
								<Map mainInfo={mainInfo} />
							</div>
						</div> : <img style={{ display: "block", margin: "60px auto", width: "80px", height: "80px" }} src={spinner} alt="Загрузка..." />
				}
			</div>
		</section>
	)
}

export default ContactInfo
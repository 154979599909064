import "./Hero.scss"
import Button from "../../Common/Button/Button"

const Hero = ({ mainInfo }) => {

	return (
		<section className="hero">
			<div className="container">
				<div className="hero__wrapper">
					<h1>
						<div><span>На захисті</span></div>
						<div><span>Ваших</span></div>
						<div><span>Законних прав</span></div>
					</h1>
					<p>Консультація та представництво ваших інтересів</p>
					<div className="hero__name">
						<div className="hero__name-owner">
							Євген Гаркуша
						</div>
						<div className="hero__name-proffesion">
							Адвокат
						</div>
					</div>
					<img src={mainInfo && mainInfo.heroOwner} alt="owner" />
					<Button btnText={"Консультація"} btnClass={"btn-request"} />
				</div>
			</div>
		</section>
	)
}

export default Hero
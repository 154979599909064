import "./Modal.scss"
import Form from "../Form/Form"

const Modal = ({ activeModal, setActiveModal }) => {

	const handleModalBackClose = (event) => {
		if (!event.target.closest(".modal__window")) {
			setActiveModal(false)
		}
	}

	return (
		<div className={`modal ${activeModal ? "active" : ""}`} onClick={handleModalBackClose}>
			<div className="modal__window">
				<Form />
				<button onClick={() => setActiveModal(false)} className="modal__window-close">&#10006;</button>
			</div>
		</div>
	)
}

export default Modal
import "./SideMenu.scss"

import { Link } from "react-router-dom"
import { useEffect } from "react"
import { facebookIcon, instagramIcon, telegramIcon, phoneCall, emailIcon, addressIcon, clockIcon } from "../../../service/image"
import useNavigateScroll from "../../../hooks/useNavigateScroll"

const SideMenu = ({ mainInfo, sideControl }) => {
	const { activeSidemenu, setActiveSidemenu } = sideControl

	const handleClickLink = useNavigateScroll()

	useEffect(() => {
		if (activeSidemenu) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = ""
		}
	}, [activeSidemenu])

	const removeActiveSide = () => {
		setActiveSidemenu(false)
	}

	return (
		<div className={`side-menu ${activeSidemenu ? "active" : ""}`}>
			<nav>
				<Link onClick={removeActiveSide} to={"/"} className="nav-link">Головна</Link>
				<a onClick={(e) => {
					removeActiveSide()
					handleClickLink(e, "about")
				}} href="#about" className="nav-link">Про мене</a>
				<Link onClick={removeActiveSide} to={"/services"} className="nav-link">Послуги</Link>
				<a onClick={(e) => {
					removeActiveSide()
					handleClickLink(e, "reviews")
				}} href="#reviews" className="nav-link">Відгуки</a>
				<Link onClick={removeActiveSide} to={"/prices"} className="nav-link">Ціни</Link>
				<Link onClick={removeActiveSide} to={"/contacts"} className="nav-link">Контакти</Link>
			</nav>

			{
				mainInfo ? <div className="side-menu__footer">
					<div className="side-menu__schedule special-link-row">
						<img src={clockIcon} alt="icon" />
						<span>
							{mainInfo.schedule}
						</span>
					</div>
					<div className="side-menu__contacts row">
						<a onClick={removeActiveSide} href={`mailto:${mainInfo.email}`} className="special-link-row">
							<img src={emailIcon} alt="icon" />
							<span>
								{mainInfo.email}
							</span>
						</a>
						<a onClick={removeActiveSide} href={`tel:${mainInfo.phones.first}`} className="special-link-row">
							<img src={phoneCall} alt="icon" />
							<span>
								{mainInfo.phones.firstPreview}
							</span>
						</a>
						<a onClick={removeActiveSide} href={`tel:${mainInfo.phones.second}`} className="special-link-row">
							<img src={phoneCall} alt="icon" />
							<span>
								{mainInfo.phones.secondPreview}
							</span>
						</a>
						<a onClick={removeActiveSide} href={mainInfo.addressLink} className="special-link-row">
							<img src={addressIcon} alt="icon" />
							<span>
								{mainInfo.address}
							</span>
						</a>
					</div>
					<div className="side-menu__social row">
						<a onClick={removeActiveSide} href={mainInfo.social.facebook} className="row">
							<img src={facebookIcon} alt="social-icon" />
						</a>
						<a onClick={removeActiveSide} href={mainInfo.social.instagram} className="row">
							<img src={instagramIcon} alt="social-icon" />
						</a>
						<a onClick={removeActiveSide} href={mainInfo.social.telegram} className="row">
							<img src={telegramIcon} alt="social-icon" />
						</a>
					</div>
				</div> : ""
			}

			<div className="side-menu__back-close" onClick={() => setActiveSidemenu(false)}>

			</div>
		</div>
	)
}

export default SideMenu
import Hero from "./Hero/Hero"
import About from "./About/About"
import TopService from "./TopService/TopService"
import Reviews from "./Reviews/Reviews"
import ContactUs from "./ContactUs/ContactUs"

const HomePage = ({ mainInfo, allServices }) => {
	return (
		<main>
			<Hero mainInfo={mainInfo} />
			<About mainInfo={mainInfo} />
			<TopService allServices={allServices} />
			<Reviews />
			<ContactUs mainInfo={mainInfo} />
		</main>
	)
}

export default HomePage
import "./TopService.scss"
import { Link } from "react-router-dom"
import ServiceCard from "../../Common/ServiceCard/ServiceCard"
import { useState, useEffect } from "react"

const TopService = ({ allServices }) => {
	const [topService, setTopService] = useState(null)

	useEffect(() => {
		if (allServices) {
			let topServices = allServices.allServices.slice(0, 6)
			setTopService(topServices)
		}
	}, [allServices])

	return (
		<section className="top-service">
			<div className="container">
				<h2>Послуги Адвоката</h2>
				<div className="top-service__wrapper row">
					{
						topService && topService.map(service => {
							return (
								<ServiceCard key={service.id} id={service.id} icon={service.icon} image={service.image} name={service.serviceName} />
							)
						})
					}
				</div>
				<Link className="nav-link top-service__link" to="/services">Інші послуги адвоката</Link>
			</div>
		</section>
	)
}

export default TopService
import "./Map.scss"

const Map = ({ mainInfo }) => {

	return (
		<iframe
			className="map"
			src={mainInfo && mainInfo.map}
			allowFullScreen=""
			loading="lazy"
			title="Це карта з місцезнаходженням"
			referrerPolicy="no-referrer-when-downgrade"
		></iframe>
	)
}

export default Map